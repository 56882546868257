.uppercase {
  text-transform: uppercase;
  font-size: 1rem;
}
.news {
  background: #f9f9f9;
  flex: none;
  position: relative;
  padding: 0;
  width: 355px;

  a {
    display: block;
  }

  p, h5 {
    display: flex;
    align-items: center;
    text-align: left;
    max-width: 85%;
  }
}

.chronicles {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px
}

.news-header {
  //height: 104px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 15px 0 10px 15px;
  color: #000;
}

.news-text {
  font-weight: 400;
  line-height: 1rem;
  margin: 10px 0 25px 15px;
  color: #515151;
}

.news-date {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 0.9rem;
  margin: 10px 0 15px 15px;
  color: #acacac;

  img {
    margin-right: 8px;
  }
}

.news-img {
  display: block;
  width: 100%;
  height: 213px;
  object-fit: cover;
}

// @media screen and (max-width: 700px) {
//   .news {
//     margin-top: 20px;
//     width: 100%;
//   }

//   .news-table {
//     margin-top: 20px;
//     width: 100%;
//     display: block;

//     td {
//       display: block;
//     }
//   }
// }


// news List

.news-list {
  background: #ffffff;
  flex: none;
  position: relative;
  padding: 0;
  box-shadow: 0px 0px 20px 0px #0000000D;

  a {
    display: block;
  }

  h5 {
    display: flex;
    align-items: center;
    text-align: left;
    max-width: 75%;
  }
}

.news-header-list {
  //height: 104px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 15px 0 10px 25px;
  color: #000;
}

.news-text-list {
  font-weight: 400;
  line-height: 20px;
  margin: 15px 0 0 25px;
  color: #515151;
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 100%;
}

.news-date-list {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  margin: 15px 0 10px 25px;
  color: #acacac;

  img {
    margin-right: 8px;
  }
}

.news-list-link-sub {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  display: inline;
  justify-content: end;

}

.news-list-link {
  position: relative;
  text-align: right;
  max-width: 100%;
  margin-bottom: 10px;
}

.news-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

